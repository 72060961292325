import React from "react";
import man_holding_racket from "../assets/images/oliver-sjostrom-padel.jpg";

export default function TopJumbotron() {
  return (
    <div className="jumbotron jumbotron-fluid">
      {/*The background image*/}
      <picture>
        <source media="(min-width:800px)" srcSet={man_holding_racket} />
        <img
          src={man_holding_racket}
          alt="Man holding a padel racket on the padel court. By Oliver Sjöström on Unsplash."
          className="jumbotron__background blur"
        />
      </picture>

      {/*Container containing the text on the image*/}
      <div className="container text-white">
        <h1 className="display-5" style={{ margin: 0 }}>
          PADEL UNIVERSE
        </h1>
        <hr className="my-4" />
        <p style={{ fontSize: `20px`, margin: 0 }}>
          Padel tar verden med storm. <br />
          Prøv denne morsomme aktiviteten hos oss. <br />
          Vi holder til rett bak Jysk på Moheim 🙌
        </p>
        <a
          href="https://www.matchi.se/facilities/padeluniverse"
          rel="noreferrer"
          target="_blank"
          className="btn btn-lg book-button"
          style={{ marginTop: `20px` }}
        >
          Book nå!
        </a>
      </div>
    </div>
  );
}
